.dashboard {
	//default
	.dashboard__visitors-chart-title {
		@include directify($directions) {
			text-align: directed("left");
		}
		span {
			color: $color-additional;
			font-size: 11px;
		}
	}

	.dashboard__visitors-chart-number {
		@include directify($directions) {
			text-align: directed("left");
		}
		font-size: 48px;
		line-height: 34px;
		margin-bottom: 10px;
	}

	.dashboard__audience-table {
		@include directify($directions) {
			text-align: directed("left");
		}
		.progress {
			margin-top: 10px;
		}

		.progress-bar {
			height: 10px;
		}
	}

	.dashboard__table-flag {
		width: 42px;
		height: 30px;
		@include directify($directions) {
			#{directed('margin-right')}: 25px;
		}
	}

	.dashboard__bounce-percent {
		@include directify($directions) {
			text-align: directed("left");
		}
		color: $color-accent;
		font-size: 48px;
		line-height: 48px;
		margin-top: 20px;
		margin-bottom: 45px;
	}

	//commerce

	.dashboard__product-img {
		max-height: 270px;
		height: 100%;
		padding: 10px 0 20px 0;
		margin-bottom: 15px;
		text-align: center;

		@include themify($themes) {
			border-bottom: 1px solid themed("colorBorder");
		}

		img {
			width: auto;
			max-width: 100%;
			max-height: 240px;
		}
	}

	.dashboard__product-title {
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.dashboard__product-subhead {
		@include directify($directions) {
			text-align: directed("left");
		}
		font-size: 13px;
		line-height: normal;
	}

	.dashboard__table-orders {
		overflow: hidden;
		min-width: 410px;

		tbody td {
			padding: 16px 10px;
		}

		.dashboard__table-orders-title {
			position: relative;
			@include directify($directions) {
				#{directed('padding-left')}: 52px;
			}
			display: flex;
			line-height: 15px;
			align-items: center;
		}
	}
	.dashboard__chart-pie--commerce,
	.dashboard__chart-pie--fitness {
		.recharts-legend-wrapper {
			@media screen and (min-width: 370px) {
				bottom: 0px !important;
			}
			@media screen and (min-width: 700px) {
				bottom: 70px !important;
			}
			@media screen and (min-width: 1020px) {
				bottom: -30px !important;
			}
			@media screen and (min-width: 1200px) {
				bottom: -55px !important;
			}
			@media screen and (min-width: 1400px) {
				bottom: -25px !important;
			}
			@media screen and (min-width: 1800px) {
				bottom: -30px !important;
			}
		}
	}

	.dashboard__table-orders-total {
		white-space: nowrap;
	}

	.dashboard__table-orders-img-wrap {
		position: absolute;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		overflow: hidden;
		@include directify($directions) {
			#{directed('left')}: 10px;
		}
	}

	.dashboard__table-orders-img {
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.dashboard__table-orders-amount {
		display: flex;

		div {
			width: 3px;
			height: 14px;
			display: inline-block;
			background-color: $color-accent;
			border-radius: 3px;
			@include directify($directions) {
				#{directed('margin-right')}: 3px;
				#{directed('margin-left')}: 0px;
			}
			margin-top: auto;
			margin-bottom: auto;
		}

		span {
			color: $color-additional;
			@include directify($directions) {
				#{directed('margin-right')}: 0px;
				#{directed('margin-left')}: 5px;
			}
			margin-top: auto;
			margin-bottom: auto;
		}

		&.dashboard__table-orders-amount--medium {
			div {
				background-color: $color-yellow;
			}
		}

		&.dashboard__table-orders-amount--low {
			div {
				background-color: $color-red;
			}
		}
	}

	.dashboard__table-orders-link {
		font-size: 12px;
		margin-top: 20px;
		display: block;

		svg {
			height: 12px;
			width: 12px;
		}
	}

	.dashboard__table-more {
		display: flex;

		& > button {
			margin: 0;
			padding: 0 5px;
			border: none;
			background-color: transparent;
			@include directify($directions) {
				#{directed('margin-left')}: auto;
			}

			&:before {
				display: none;
			}

			svg {
				margin: 0;
				height: 20px;
				width: 20px;
				fill: $color-additional;
				transition: all 0.3s;
			}

			&:hover,
			&:not([disabled]):not(.disabled):active,
			&:focus {
				background: transparent;

				svg {
					fill: $color-accent;
				}
			}
		}

		& > div {
			min-width: 90px;
			width: 100%;
		}

		button {
			font-size: 13px;
		}
	}

	.dashboard__sales-toggle {
		label {
			margin: 0;

			&:after {
				box-shadow: none;
				background-color: $color-red;
			}
		}
	}

	.dashboard__sales-toggle-left {
		@include directify($directions) {
			#{directed('margin-left')}: auto;
		}
		@include directify($directions) {
			#{directed('margin-right')}: 12px;
		}
		color: $color-additional;
		pointer-events: none;
	}

	.dashboard__sales-toggle-right {
		@include directify($directions) {
			#{directed('margin-left')}: 12px;
			#{directed('margin-right')}: auto;
		}
		color: $color-additional;
		pointer-events: none;
	}

	.dashboard__sales-report {
		display: flex;

		& > div {
			width: 100%;
			@include directify($directions) {
				#{directed('margin-right')}: 25px;
			}
			margin-bottom: 0;

			&:last-child {
				@include directify($directions) {
					#{directed('margin-right')}: 0px;
				}
			}
		}

		p {
			margin-top: 0;
		}
	}

	.dashboard__sales-report-title {
		color: $color-additional;
		font-size: 12px;
		line-height: 13px;
		margin-bottom: 10px;
	}

	.dashboard__sales-report-now {
		font-weight: 500;
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 4px;
	}

	.dashboard__sales-report-plan {
		font-size: 11px;
		line-height: 15px;
		color: $color-additional;
		opacity: 0.5;
		margin-bottom: 10px;
	}

	.dashboard__sales-report-value {
		margin-bottom: 0;
	}

	//fitness

	.dashboard__weight-stats {
		display: flex;
		margin-bottom: 20px;
	}

	.dashboard__weight-stat {
		@include directify($directions) {
			#{directed('margin-right')}: 40px;
		}

		@media screen and (min-width: 768px) {
			@include directify($directions) {
				#{directed('margin-right')}: 80px;
			}
		}

		&:last-child {
			@include directify($directions) {
				#{directed('margin-right')}: 0px;
			}
		}
	}

	.dashboard__weight-stat-title {
		margin-top: 0;
		margin-bottom: 3px;
	}

	.dashboard__weight-stat-value {
		font-size: 36px;
		line-height: 48px;

		@media screen and (min-width: 768px) {
			font-size: 48px;
			line-height: 62px;
		}

		&.dashboard__weight-stat-value--control {
			color: $color-yellow;
		}

		&.dashboard__weight-stat-value--total {
			color: $color-red;
		}
	}

	.dashboard__health-chart-card {
		text-align: center;
	}

	.dashboard__goal {
		font-size: 12px;
		color: $color-additional;
	}

	.dashboard__health-chart {
		position: relative;
	}

	.dashboard__health-chart-info {
		position: absolute;
		width: 100%;
		top: calc(50% - 55px);
		animation: label 1.5s ease-in;

		p {
			margin: 0;
		}
	}

	.dashboard__health-chart-number {
		font-size: 56px;
		line-height: 60px;

		@include themify($themes) {
			color: themed("colorFitness");
		}
	}

	.dashboard__health-chart-units {
		color: $color-additional;
		margin-top: 5px;
	}

	.dashboard__competitor {
		display: flex;
		padding: 10px 0;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: calc(100% + 65px);
			top: 0;
			@include directify($directions) {
				#{directed('left')}: -30px;
			}
			z-index: 0;
			opacity: 0;
			transition: all 0.3s;

			@include themify($themes) {
				background: themed("colorHover");
			}
		}

		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}

	.dashboard__competitor-img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		@include directify($directions) {
			#{directed('margin-right')}: 10px;
		}
		min-width: 40px;
		z-index: 1;

		img {
			height: 100%;
			min-width: 100%;
		}
	}

	.dashboard__competitor-info {
		@include directify($directions) {
			text-align: directed("left");
		}
		z-index: 1;
	}

	.dashboard__competitor-name {
		font-weight: 500;
	}

	.dashboard__competitor-result {
		color: $color-red;
		margin-top: 0;
		font-size: 14px;
	}

	// > 2

	.dashboard__area {
		font-size: 11px;

		.recharts-legend-wrapper {
			bottom: 0 !important;
		}
	}

	.dashboard__total {
		display: flex;
		margin-top: -15px;

		&.dashboard__total--area {
			margin-top: -55px;
		}
	}

	.dashboard__chart-container {
		flex: 1;
		width: 0;
		min-width: 0;

		@media (min-width: 1200px) {
			& {
				min-width: 0;
				max-width: 180px;
			}
		}
	}

	.dashboard__total-stat {
		font-size: 18px;
		height: 24px;
		@include directify($directions) {
			#{directed('margin-right')}: auto;
			#{directed('padding-right')}: 10px;
		}
		margin-top: auto;
		line-height: 24px;
	}

	.dashboard__trend-icon {
		fill: $color-accent;
		height: 24px;
		width: 24px;
		@include directify($directions) {
			#{directed('margin-right')}: 3px;
			transform: #{directed("mirrorY")};
		}
		min-width: 24px;
		margin-top: auto;
	}

	.dashboard__stat {
		display: flex;
		margin-top: 40px;
		margin-bottom: 10px;
		align-items: center;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.dashboard__stat-info {
			@include directify($directions) {
				#{directed('margin-right')}: 0px;
				#{directed('margin-left')}: 30px;
			}
			@include directify($directions) {
				text-align: directed("left");
			}
			margin-top: auto;
			margin-bottom: auto;

			p {
				color: $color-additional;
			}
		}

		.dashboard__stat-number {
			margin-top: 10px;
		}

		.dashboard__stat-chart {
			position: relative;
			text-align: center;
		}

		.dashboard__stat-label {
			position: absolute;
			font-size: 18px;
			line-height: 20px;
			top: calc(50% - 10px);
			width: 100%;
			margin: 0;
			animation: label 1.5s ease-in;
			color: #dddddd;
		}

		&.dashboard__stat--budget {
			text-align: center;
			flex-wrap: wrap;

			.dashboard__stat-chart {
				margin: auto;
			}

			.dashboard__stat-label {
				font-size: 36px;
			}

			.dashboard__stat-main {
				width: 100%;

				hr {
					margin-bottom: 30px;
					margin-top: 40px;
				}
			}

			.dashboard__stat-main-title {
				color: $color-additional;
			}

			.dashboard__stat-main-number {
				color: $color-red;
				font-size: 48px;
				line-height: 34px;
				margin-top: 15px;
			}

			.dashboard__stat-data {
				width: 100%;
				display: flex;
				justify-content: space-around;
				margin-top: 10px;

				p {
					margin: 0;
				}
			}

			.dashboard__stat-data-number {
				font-size: 18px;
				line-height: 34px;
				font-weight: 500;
			}
		}

		@media screen and (min-width: 1200px) and (max-width: 1439px) {
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 20px;

			.dashboard__stat-info {
				margin: 0;
			}

			.dashboard__stat-chart {
				margin-bottom: 15px;
			}
		}

		@media screen and (min-width: 1200px) and (max-width: 1539px) {
			.dashboard__stat-chart {
				svg,
				div {
					width: 80px !important;
					height: 80px !important;
				}
			}
		}
	}

	@keyframes label {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.dashboard__chart-pie {
		@include directify($directions) {
			text-align: directed("left");
		}
		height: 280px !important;

		.recharts-surface {
			width: 100%;
			height: 100%;
		}

		@media screen and (min-width: 768px) {
			&.dashboard__chart-pie--crypto {
				height: 300px !important;

				.dashboard__chart-pie-container {
					height: 300px !important;
				}
			}

			&.dashboard__chart-pie--commerce {
				height: 360px !important;

				.dashboard__chart-pie-container {
					height: 360px !important;
				}
			}

			&.dashboard__chart-pie--fitness {
				height: 360px !important;

				.dashboard__chart-pie-container {
					height: 360px !important;
				}
			}
		}
		@media screen and (max-width: 768px) {
			.recharts-legend-wrapper {
				position: relative !important;
			}
		}
		@media screen and (max-width: 480px) {
			&.dashboard__chart-pie--crypto {
				height: 240px !important;

				.dashboard__chart-pie-container {
					height: 180px !important;
				}

				.dashboard__chart-legend {
					@include directify($directions) {
						#{directed('margin-left')}: -10px;
					}

					li {
						margin-top: 0;
					}
				}
			}
		}
	}

	.dashboard__chart-pie-container {
		@include directify($directions) {
			#{directed('padding-left')}: 0px;
		}
		height: 200px !important;
	}

	.dashboard__chart-legend {
		padding: 0;
		list-style: none;

		span {
			height: 10px;
			width: 10px;
			border-radius: 50%;
			display: inline-block;
			@include directify($directions) {
				#{directed('margin-right')}: 10px;
			}
			// didn't rtl
			margin-right: 10px;
		}

		li {
			margin-top: 5px;

			@include themify($themes) {
				color: themed("colorText");
			}
		}
	}

	.dashboard__card-widget {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	//crypto

	.dashboard__btc {
		color: #f6a81e;
	}

	.dashboard__eth {
		color: #5e62e6;
	}

	.dashboard__neo {
		color: #3ddb42;
	}

	.dashboard__ste {
		color: #21cbe6;
	}

	.dashboard__eos {
		color: #6d6a6a;
	}

	.dashboard__lit {
		color: #b7b3b3;
	}

	.dashboard__table-crypto {
		tbody td {
			padding: 17px 10px;
			white-space: nowrap;
		}

		.dashboard__table-crypto-chart {
			padding: 10px;
			width: 180px;
		}
	}

	.dashboard__place-order {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media screen and (min-width: 900px) {
			flex-wrap: nowrap;
		}

		@media screen and (min-width: 1200px) {
			flex-wrap: wrap;
		}

		@media screen and (min-width: 1360px) {
			flex-wrap: nowrap;
		}
	}

	// booking

	.dashboard__booking-card {
		padding-top: 20px;
		padding-bottom: 25px;
		padding-right: 30px;
		padding-left: 30px;

		.dashboard__booking-card-progress-label {
			@include directify($directions) {
				text-align: directed("right");
			}
			font-size: 14px;
			font-weight: 500;
		}

		.dashboard__trend-icon {
			fill: #d8dfe9;
			@include directify($directions) {
				#{directed('margin-right')}: 0px;
			}
			margin-top: auto;
		}
	}

	.dashboard__booking-total-container {
		display: flex;
		justify-content: space-between;
	}
	.dashboard__booking-total-title {
		direction: ltr;
		font-size: 28px;
		font-weight: 500;
		line-height: normal;
		color: $color-accent;
	}


	.span-link {
		border:none;
		width: 100%;
		background: transparent;
		text-align: left;
		color: $color-accent;
		cursor: pointer;
	}


	.dashboard__booking-total-title--red {
		color: $color-red;
	}

	.dashboard__booking-total-title--green {
		color: $color-green;
	}

	.dashboard__booking-total-title--blue {
		color: $color-blue;
	}

	.dashboard__booking-total-description {
		@include directify($directions) {
			text-align: directed("left");
		}
		opacity: 0.7;
		font-size: 12px;
		font-weight: 500;
		line-height: normal;
		color: #555555;
		margin-top: 3px;
		text-transform: uppercase;
		.theme-dark & {
			color: #dddddd;
		}
	}

	.dashboard__booking-reservations-panel:not(.panel--collapse) {
		// height: calc(100% - 138px);
	}

	.dashboard__booking-reservations {
		@include directify($directions) {
			text-align: directed("left");
		}

		margin-top: -10px;

		&-title {
			font-size: 13px;
			color: #555555;
			.theme-dark & {
				color: #dddddd;
			}
		}

		&-number {
			font-size: 48px;
			line-height: 34px;
			margin-top: 15px;
			margin-bottom: 10px;
			color: #555555;
			.theme-dark & {
				color: #dddddd;
			}
		}

		&-chart {
			height: 150px;
			position: relative;

			@media screen and (min-width: 1400px) and (max-width: 1599px) {
				height: 230px;
			}

			.recharts-surface {
				width: 100%;
				height: 100%;
			}

			.recharts-legend-wrapper {
				width: 100% !important;
				display: block;
				position: static !important;
				@media screen and (min-width: 370px) {
					//width: 115px !important;
					bottom: 0px;
				}
				@media screen and (min-width: 992px) {
					//width: 105px !important;
					bottom: 70px;
				}
				@media screen and (min-width: 1020px) {
					//width: 115px !important;
					bottom: -30px;
				}
				@media screen and (min-width: 1400px) {
					//width: 125px !important;
					bottom: -55px;
				}
				@media screen and (min-width: 1800px) {
					//width: 115px !important;
					bottom: -30px;
				}
			}

			.recharts-responsive-container {
				width: 100% !important;
				height: 100% !important;
				@media screen and (min-width: 1400px) and (max-width: 1599px) {
					height: calc(100% - 18px) !important;
				}
			}

			&-container {
				width: 100% !important;
				height: 100% !important;

				display: flex;
				flex-direction: row-reverse;
				align-items: center;

				@media screen and (min-width: 992px) {
					width: 100% !important;
					height: 100% !important;
				}
				@media screen and (min-width: 1400px) and (max-width: 1599px) {
					flex-direction: column;
					align-items: baseline;
				}
			}

			&-legend {
				li {
					display: flex;
					align-items: baseline;
				}

				p {
					display: inline-block;
					margin: 0;
				}
			}
		}

		&-link {
			margin-top: 16px;
			color: #48b5ff;
			position: absolute;
			bottom: 12px;
			right: 16px;
			font-size: 12px;
			line-height: 1.5;
			cursor: pointer;

			&-icon {
				width: 12px;
				height: 12px;
			}

			@media screen and (min-width: 1400px) {
				display: inline-block;
				// position: static;
			}
		}
	}

	.dashboard__booking-our-mission {
		@include directify($directions) {
			text-align: directed("left");
		}
		height: 108px;
		background: #b8e986;
		border-radius: 5px;
		padding: 15px 13px;
		position: relative;
		overflow: hidden;

		svg {
			position: absolute;
			height: 80px;
			width: 80px;
			top: calc(50% - 40px);
			opacity: 0.3;
			@include directify($directions) {
				#{directed('right')}: -23px;
			}
			color: #ffffff;
		}

		p {
			margin: 0;
			font-size: 16px;
			line-height: 1.25;
			color: #ffffff;
			max-width: 100%;
			@media screen and (min-width: 376px) and (max-width: 991px) {
				max-width: calc(100% - 60px);
			}
			@media screen and (min-width: 1200px) and (max-width: 1550px) {
				font-size: 14px;
			}
			@media screen and (max-width: 420px) {
				font-size: 14px;
			}
		}

		.dashboard__booking-our-mission-title {
			font-size: 24px;
			font-weight: 500;
			color: #ffffff;
			margin-bottom: 4px;
			@media screen and (min-width: 1200px) and (max-width: 1399px) {
				font-size: 20px;
			}
		}
	}

	.dashboard__weekly-stat {
		hr {
			margin-bottom: 20px;
		}

		&-chart {
			display: flex;
			margin: 0 0 31px 0;
			flex-wrap: wrap;
			justify-content: space-around;

			.recharts-surface {
				width: 100%;
				height: 100%;
			}

			&-item {
				width: 110px;
				margin-bottom: 15px;

				@media screen and (max-width: 370px) {
					@include directify($directions) {
						#{directed('margin-left')}: 10px;
					}
				}

				@media screen and (min-width: 1200px) {
					@include directify($directions) {
						#{directed('margin-left')}: 10px;
					}
				}

				@media screen and (min-width: 1400px) {
					@include directify($directions) {
						#{directed('margin-left')}: 0px;
					}
				}
			}

			&-pie {
				display: block;
				position: relative;
				text-align: center;
				height: 110px;

				.recharts-responsive-container {
					width: 100% !important;
				}
			}

			&-pie-wrapper {
				width: 100% !important;
				height: 100% !important;
			}
		}

		&-label {
			position: absolute;
			top: calc(50% - 10px);
			width: 100%;
			margin: 0;
			animation: label 1.5s ease-in;
			font-size: 24px;
			line-height: 24px;
		}

		&-info {
			margin-top: 10px;
			font-size: 13px;
			line-height: 1.38;
			text-align: center;
			p {
				color: $color-additional;
			}
		}
	}

	.dashboard__social-stat {
		&-item {
			@include directify($directions) {
				text-align: directed("left");
			}
			display: flex;
			align-items: flex-end;
			width: 100%;

			margin-top: 10px;
			&:first-child {
				margin-top: 0;
			}
		}
		&-title {
			width: 40%;
			font-size: 13px;
			line-height: 1.38;
			color: $color-additional;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&-progress {
			width: 60%;

			&.progress--wide {
				width: 80%;
			}

			.progress__label {
				@include directify($directions) {
					text-align: directed("right");
				}
			}
		}
	}

	.dashboard__occupancy-table {
		@include directify($directions) {
			text-align: directed("left");
		}
		td {
			font-size: 13px;
			padding: 5px;
		}

		td.td-head {
			color: #555555;
			.theme-dark & {
				color: #dddddd;
			}
		}

		td.td-blue {
			font-weight: 500;
			color: #48b5ff;
		}

		td.td-green {
			font-weight: 500;
			color: #b8e986;
		}
		td.td-gray {
			color: #999999;
		}
	}

	.dashboard__place-order-form {
		position: relative;
		width: 100%;
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}

		form {
			padding: 20px 20px 30px 20px;
			border-radius: 5px;
			margin-top: 10px;

			@include themify($themes) {
				background-color: themed("colorBorder");
			}
		}

		.form__form-group-label {
			width: 50px;
		}

		.form__form-group-field {
			width: calc(100% - 50px);
		}

		.form__button-toolbar {
			@include directify($directions) {
				#{directed('margin-left')}: 0px;
			}
			width: 100%;

			button {
				width: 100%;
			}
		}

		input {
			@include themify($themes) {
				background-color: themed("colorBackground");
			}
		}

		@media screen and (min-width: 900px) {
			width: calc(50% - 15px);
			margin-top: 0;
		}

		@media screen and (min-width: 1200px) {
			width: 100%;
			margin-top: 20px;

			&:first-child {
				margin-top: 0;
			}
		}

		@media screen and (min-width: 1360px) {
			width: calc(50% - 15px);
			margin-top: 0;
		}
	}

	.dashboard__place-order-form-subhead {
		position: absolute;
		font-size: 12px;
		top: 4px;
		@include directify($directions) {
			#{directed('right')}: 0px;
		}
	}

	.dashboard__chart-pie.dashboard__chart-pie--crypto {
		@media screen and (min-width: 1200px) {
			.dashboard__chart-pie-container {
				@include directify($directions) {
					#{directed('padding-left')}: 0px;
				}
			}

			.recharts-legend-wrapper {
				bottom: -110px !important;
			}
		}

		@media screen and (min-width: 1360px) {
			.recharts-legend-wrapper {
				bottom: 5px !important;
			}
		}
	}

	//mobile app

	.mobile-app-widget {
		.mobile-app-widget__top-line {
			display: flex;

			.mobile-app-widget__total-stat {
				direction: ltr;
				padding: 0;
				font-size: 28px;
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				letter-spacing: normal;
				@include directify($directions) {
					#{directed('margin-right')}: auto;
				}
				line-height: normal;
			}
		}

		.mobile-app-widget__top-line--pink {
			.mobile-app-widget__total-stat {
				color: #ff4861;
			}
		}

		.mobile-app-widget__top-line--lime {
			.mobile-app-widget__total-stat {
				color: #b8e986;
			}
		}

		.mobile-app-widget__top-line--blue {
			.mobile-app-widget__total-stat {
				color: #48b5ff;
			}
		}

		.mobile-app-widget__top-line--turquoise {
			.mobile-app-widget__total-stat {
				color: #4ce1b6;
			}
		}

		.mobile-app-widget__title {
			@include directify($directions) {
				text-align: directed("left");
			}
			text-transform: uppercase;
			position: relative;
			margin: 0 0 20px;

			h5 {
				font-size: 12px;
				color: #555555;
				font-weight: 500;
			}
		}

		.dashboard__trend-icon {
			margin: 0;
			height: 29px;
			min-width: 28px;
			fill: #d8dfe9;
		}

		.progress__label {
			font-size: 14px;
			font-weight: 500;
		}

		.progress__label {
			@include directify($directions) {
				#{directed('right')}: -7px;
			}
		}

		.progress-bar {
			border-radius: 5px;
			background-color: unset;
		}
	}

	.dashboard__users-stat {
		.dashboard__trend-icon {
			fill: $color-light-gray;
			height: 24px;
			width: 24px;
			@include directify($directions) {
				#{directed('margin-right')}: 3px;
			}
			min-width: 24px;
			margin-top: auto;
		}
	}

	.dashboard__current-users {
		display: flex;
		align-items: center;
		flex-direction: column;

		.dashboard__current-users-chart {
			position: relative;
			width: 100%;

			div,
			svg {
				width: 100% !important;
				margin: 0 auto;
			}

			.dashboard__current-users-label {
				margin: 0;
				position: absolute;
				top: calc(50% + 10px);
				text-align: center;
				@include directify($directions) {
					#{directed('left')}: calc(50% - 30px);
				}
				font-size: 38px;
			}
		}

		.dashboard__current-users-info {
			display: flex;
			align-items: flex-start;
			width: 100%;
			justify-content: space-between;
			font-size: 13px;
			line-height: 1.54;
			color: #555555;

			p {
				margin: 0;
			}

			.dashboard__current-users-day {
				display: flex;
				flex-direction: column;
				align-items: center;

				& span:last-child {
					font-size: 16px;
					line-height: 1.25;
					font-weight: 500;
					color: #555555;
				}
			}
		}

		@media screen and (min-width: 1539px) {
			.dashboard__current-users-info {
				padding: 0 20px;
			}
		}

		@media screen and (min-width: 1200px) and (max-width: 1539px) {
			.dashboard__current-users-chart {
				svg,
				div {
					height: 100px !important;
				}
			}
		}

		@media screen and (min-width: 768px) and (max-width: 1023px) {
			.dashboard__current-users-info {
				padding: 0 75px;
			}
		}
	}

	.dashboard__active-users-chart {
		overflow: hidden;
	}

	.dashboard__carousel {
		margin-bottom: -25px;

		.slick-dots {
			bottom: -35px;
		}

		.slick-arrow.slick-prev,
		.slick-arrow.slick-next {
			background: none;
			opacity: 0;
			pointer-events: none;
		}

		.dashboard__carousel-slide {
			@include directify($directions) {
				text-align: directed("left");
			}
			border-radius: 5px;
			padding: 15px 13px;
			position: relative;
			overflow: hidden;

			svg {
				position: absolute;
				height: 80px;
				width: 80px;
				top: calc(50% - 40px);
				opacity: 0.3;
				@include directify($directions) {
					#{directed('right')}: -20px;
				}

				@include themify($themes) {
					fill: themed("colorIcon");
				}
			}

			p {
				color: $color-additional;
				margin: 0;
			}

			.dashboard__carousel-title {
				color: $color-red;
				font-size: 24px;
				font-weight: 500;
			}

			@include themify($themes) {
				background: themed("colorBackgroundBody");
			}

			&.dashboard__carousel-slide--red {
				background: $color-red;

				p {
					color: white;
					opacity: 0.45;
				}

				svg {
					fill: #ffffff;
				}

				.dashboard__carousel-title {
					opacity: 1;
				}
			}
		}
	}
}
