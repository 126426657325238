.rc-time-picker {
	width: 100%;
}

.rc-time-picker-input {
	border-radius: 0;
}

.rc-time-picker-panel {
	padding-top: 3px;
}

.rc-time-picker-panel-input-wrap {
	display: none;
}

.rc-time-picker-panel-inner {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	border: none;
	border-radius: 0;
	margin-top: 32px;
}

.rc-time-picker-panel-select {
	overflow: hidden;
	@include directify($directions) {
		#{directed('border-right')}: 1px solid #eff1f5;
	}

	ul {
		@include directify($directions) {
			#{directed('padding-right')}: 30px;
		}
		overflow-y: scroll;
		overflow-x: hidden;
		width: 86px;
		height: 144px;
	}

	li {
		padding: 0;
		text-align: center;
		transition: 0.3s;
		width: 56px;
	}
}

li.rc-time-picker-panel-select-option-selected {
	background: $color-blue;
	color: white;

	&:hover {
		background: $color-blue-hover;
	}
}

.rc-time-picker-clear {
	@include directify($directions) {
		#{directed('right')}: 6px;
		#{directed('left')}: auto;
	}
}

.rc-time-picker-panel.theme-dark {
	.rc-time-picker-panel-inner {
		background: #232329;
		color: #dddddd;

		.rc-time-picker-panel-select li:hover {
			background: #33333a;
			color: white;
		}

		li.rc-time-picker-panel-select-option-selected {
			background: #33333a;
			color: white;
		}
	}
}
