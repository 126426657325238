.user-detail-title {
	background: #fff;
	margin: -12px 0 0 10px;
	position: absolute;
	z-index: 10;
}

.user-detail-row {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 20px;
}
